import { useEmail } from '@pretto/website/src/components/Fields/email'
import { trackAction } from '@pretto/website/src/utilities/tracking'

const PURCHASE_OFFER_APP_URL = 'https://app.staging.pretto.fr/purchase-offer'

export const usePurchaseOfferHeader = () => {
  const { debouncedError, defaultErrorMessage, email, error, onChange } = useEmail()

  const inputProps = {
    placeholder: 'Votre adresse email',
  }

  const track = option => {
    trackAction('PURCHASE_OFFER_WEB_CLICKED', { purchase_offer_web_option: option })
  }

  const handleSubmit = () => {
    track('hero')
    window.open(`${PURCHASE_OFFER_APP_URL}?email=${encodeURIComponent(email)}&signup_kind=hero`)
  }

  const props = {
    emailInputProps: {
      email,
      inputProps,
      isButtonDisabled: !!error,
      isMessageTips: true,
      isSingle: true,
      message: !error ? '' : debouncedError && defaultErrorMessage,
      onChange,
      onSubmit: handleSubmit,
      state: !email ? null : debouncedError ? 'error' : null,
    },
  }

  return props
}
